import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = () => (
  <div>
    <div className={styles.section}>
      <Container>
        <Row className="align-items-center">
          <Col lg={12}>
            <p className={styles.evolve}>Careers at InvoZone</p>
            <h1>Your Success Story Starts Here</h1>
            <div className={styles.paragraph}>
              <span>
                Your potential knows no bounds, and this is where it all begins!
                We provide a launchpad for your ambitions.
              </span>
            </div>
            <div className={styles.paragraph}>
              <span>
                Join Invozone's dynamic team of developers, consultants, and
                marketers building exceptional apps and software, powered by
                expert consultancy. Our global offices in the US, Canada,
                Malaysia, and Pakistan redefine possibilities through a hybrid
                approach. Dream, grow & succeed with us.
              </span>
            </div>
          </Col>
        </Row>
        <div className={styles.futureBtn}>
          <p className={styles.future}>
            Shaping The Future Workforce Landscape
          </p>
        </div>
      </Container>
    </div>
    <div className={styles.futureBtn2}>
      <p className={styles.future}>Shaping The Future Workforce Landscape</p>
    </div>
  </div>
)

export default Banner
