import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import * as styles from "./Aspiration2.module.scss"

const SubmitIdea = () => {
  return (
    <div className={styles.SubmitIdea}>
      <div className={styles.aspireBg}>
        <Col lg={7} md={12}>
          <div className={styles.heading}>
            <h2>
              Curious Much?
              <span> Become a part of the InvoZone family</span>, where your
              aspirations come first
            </h2>
            <Link to="/about-us/" className={styles.aspireBtn}>
              Get More Details Here
            </Link>
          </div>
        </Col>
        <div>
          <img
            src="https://invozone-backend.s3.us-east-1.amazonaws.com/image2_4d81c6daf2fba49d91c05b7b6184265b_b7ad7bfdec.webp"
            decoding="async"
            loading="lazy"
            className={styles.bannerImg}
            alt="aspiration"
            width={"100%"}
            height={"100%"}
          />
        </div>
      </div>
    </div>
  )
}

export default SubmitIdea
