// extracted by mini-css-extract-plugin
export var Frame = "Engineering-module--Frame--52fd6";
export var Vector = "Engineering-module--Vector--cb7f4";
export var card = "Engineering-module--card--4a72f";
export var cards = "Engineering-module--cards--08f41";
export var cardsData = "Engineering-module--cardsData--7f41c";
export var cir = "Engineering-module--cir--59e0e";
export var doots = "Engineering-module--doots--7daa8";
export var dots = "Engineering-module--dots--dae64";
export var dots1 = "Engineering-module--dots1--daa4f";
export var imgBg = "Engineering-module--imgBg--971cb";
export var number = "Engineering-module--number--dce05";
export var years = "Engineering-module--years--31bc0";