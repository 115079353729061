import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import { StaticImage } from "gatsby-plugin-image"
import Row from "react-bootstrap/Row"
import * as styles from "./WhyWork.module.scss"

const card = [
  {
    dec: `Enjoy regional flexibility, allowing you to work easily from your location.`,
  },
  {
    dec: `Everyday is unique! We value seizing the moment to gain new experiences.`,
  },
  {
    dec: `Our commitment is towards prioritizing your growth with amazing perks.`,
  },
  {
    dec: `Promoting equal workforce is our pledge, within teams & the ecosystem.`,
  },
]

const WhyWork = ({ strapiData }) => {
  return (
    <div className={styles.hireCyber}>
      <Container>
        <Row className="gap-30">
          <Col
            lg={{ span: 6, order: 1 }}
            md={{ span: 12, order: 2 }}
            xs={{ span: 12, order: 2 }}
          >
            <div className={styles.hire}>
              <h2>Why Choose InvoZone for Your Next Career Shift?</h2>
              <p className={styles.description}>
                With us, it's beyond a job – it's a unique chance. We're always
                on a look out for skilled professionals to shape the digital and
                tech spheres together!
              </p>
              <p className={styles.description}>
                From devs to designers, marketers to analysts, we're a team of
                dedication, rapid learners, and boundless creativity. We like
                new ideas, do well in uncertain situations, and believe in being
                honest and real. 
              </p>
              <p className={styles.description}>
                Does this fit you? If your answer is yes, here are a few more
                reasons why you should consider InvoZone for your next company
                of choice!
              </p>
            </div>
            <div className={styles.cards}>
              {card?.map((i, e) => (
                <div className={styles.talent} key={e}>
                  <img
                    className={styles.tick}
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/green_tick_b6c91f9ead.png"
                    alt={"check_mark"}
                    style={{ height: "24px", width: "24px" }}
                  />
                  <div className={styles.text}>
                    <p>{i?.dec}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.teamBtn}>
              {strapiData?.buttons[0] && (
                <Link to={strapiData?.buttons[0]?.url} className={styles.btn}>
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
          <Col
            lg={{ span: 6, order: 2 }}
            md={{ span: 12, order: 1 }}
            xs={{ span: 12, order: 1 }}
          >
            <div className={styles.hireImg}>
              <StaticImage
                src={
                  "https://invozone-backend.s3.us-east-1.amazonaws.com/why_choose_invozone_4527867e5edf912d689c76f42169d65a_1b54bb3a16.webp"
                }
                alt="work_image"
                placeholder="blurred"
                decoding="async"
                loading="lazy"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WhyWork
