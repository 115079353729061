// extracted by mini-css-extract-plugin
export var Onsite = "Vacancies2-module--Onsite--80d2e";
export var applyBtn = "Vacancies2-module--applyBtn--71ab0";
export var con = "Vacancies2-module--con--28476";
export var full = "Vacancies2-module--full--0450c";
export var headingWrap = "Vacancies2-module--headingWrap--2b28d";
export var job = "Vacancies2-module--job--e135c";
export var jobCards = "Vacancies2-module--jobCards--241b5";
export var loc = "Vacancies2-module--loc--2c22d";
export var location = "Vacancies2-module--location--8cf9e";
export var mail = "Vacancies2-module--mail--b1c6b";
export var mailto = "Vacancies2-module--mailto--3704e";
export var my_custom_scrollbar = "Vacancies2-module--my_custom_scrollbar--76260";
export var overview = "Vacancies2-module--overview--965cf";
export var scrollBg = "Vacancies2-module--scrollBg--092e9";
export var scrollOver = "Vacancies2-module--scrollOver--5c0c1";
export var scrollOverFlow = "Vacancies2-module--scrollOverFlow--e1033";
export var table_card_view = "Vacancies2-module--table_card_view--86097";
export var vacancies = "Vacancies2-module--vacancies--d6e89";