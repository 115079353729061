import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Aspiration.module.scss"

const SubmitIdea = () => {
  return (
    <div className={styles.SubmitIdea}>
      <Container>
        <div className={styles.aspireBg}>
          <Row>
            <Col lg={7} md={12}>
              <div className={styles.heading}>
                <h2>
                  Curious Much?
                  <span> Become a part of the InvoZone family</span>, where your
                  aspirations come first
                </h2>
                <Link to="/about-us/" className={styles.aspireBtn}>
                  Get More Details Here
                </Link>
              </div>
            </Col>
            <Col lg={5} md={12}>
              <div className={styles.bannerImg}>
                <img
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/become_part_of_invozone_082461c5ea31e3470e648c2cd669a474_5a3f70500c.webp"
                  decoding="async"
                  loading="lazy"
                  alt="aspiration"
                  height={"100%"}
                  width={"100%"}
                />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default SubmitIdea
