import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import ViewableCountUp from "../../utils/ViewableCountUp"
import * as styles from "./Engineering.module.scss"

const cardsData = [
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/300_92a60d8d1d.webp",
    title: 300,
    sign: `+`,
    description: `Successful Projects`,
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/97_6b3243a0dd.webp",
    title: 97,
    sign: `%`,
    description: `Customer Satisfaction Rate`,
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/500_7864869827.webp",
    title: 500,
    sign: `+`,
    description: `Developers & Engineers`,
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/10_e33a2cc6be.webp",
    title: 10,
    sign: `+`,
    description: `Years of Experience`,
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/3_7139e7ac64.webp",
    title: 3,
    sign: ``,
    description: `Global Offices`,
  },
  {
    img: "https://invozone-backend.s3.us-east-1.amazonaws.com/150_354fff118b.webp",
    title: 150,
    sign: `+`,
    description: `Happy Customers`,
  },
]

const Engineering = () => {
  return (
    <div className={styles.number}>
      <Container>
        <h2>InvoZone: A Hub of Expertise, Engineering Next-Gen Solutions.</h2>
        <p>
          With a passion for innovation and a commitment to creating lasting
          impact, we actively shape the future of technology as per the industry
          standards.
        </p>
        <div className={styles.cardsData}>
          <Row className="gap-30">
            {cardsData?.map((item, index) => (
              <Col xl={4} md={6} key={index}>
                <div className={styles.cards}>
                  <div className={styles.imgBg}>
                    <img
                      src={item?.img}
                      decoding="async"
                      loading="lazy"
                      alt="Slider"
                      height={60}
                      width={60}
                    />
                  </div>
                  <div className={styles.years}>
                    <ViewableCountUp
                      start={0}
                      end={item?.title}
                      duration={5}
                      suffix={item?.sign}
                    />
                    <p>{item?.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default Engineering
