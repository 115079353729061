import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Table from "react-bootstrap/Table"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import locaImg from "../../images/Career-Page/location.svg"
import * as styles from "./Vacancies2.module.scss"
import "./Vacancies.scss"

const Vacancies = ({ vacancies }) => {
  const [location, setLocation] = useState({
    value: "select country",
    label: "Select Country",
  })
  const [city, setCity] = useState([])

  const [apply, setApply] = useState(false)
  const [jobsData, setJobsData] = useState([])

  const filterResults = label => {
    const filteredResults = vacancies?.filter(
      ({ country }) => country?.toLowerCase() === label.toLowerCase()
    )
    setJobsData(filteredResults)
  }
  const filterResults2 = label => {
    const filteredResults = vacancies?.filter(({ cities, country }) => {
      if (location?.label?.toLowerCase() === "pakistan" && label?.length > 0) {
        return cities?.strapi_json_value?.find(e =>
          label?.some(el => el?.label?.toLowerCase() === e.toLowerCase())
        )
      } else return country?.toLowerCase() === "pakistan"
    })

    setJobsData(filteredResults)
  }

  const handleChange = e => {
    setLocation(e)
    setCity([])
    filterResults(e?.label)
  }
  const cityChange = e => {
    setCity(e)
    filterResults2(e)
  }

  useEffect(() => {
    vacancies.length > 0 && filterResults("Pakistan")
  }, [vacancies])

  return (
    <section className={`pb-0 ${styles.vacancies}`}>
      <Container className={styles.con}>
        <div className={styles.scrollBg}>
          <h2>View Current Job Openings</h2>
          <div className={`${styles.my_custom_scrollbar}`}>
            <Table borderless className={`mb-0 pb-0 ${styles.table_card_view}`}>
              <div>
                <div className={styles.scrollOverFlow}></div>
                <div className={styles.scrollOver}>
                  {jobsData.map(
                    (
                      { title, slug, jobType, overview, country, cities },
                      i
                    ) => (
                      <React.Fragment key={i}>
                        <div className={styles.jobCards}>
                          <div className={`mt-0`}>
                            <div className={styles.full}>{jobType}</div>
                            <div className={styles.Onsite}>Onsite</div>
                            <div
                              className={styles.job}
                              onClick={() => navigate(`/jobs/${slug}/`)}
                            >
                              {title}
                            </div>
                          </div>
                          <div className={styles.overview}>{overview}</div>
                          <Link
                            to={`/jobs/${slug}/`}
                            className="customstylebutton normal_custom_btn"
                          >
                            <Button className={styles.applyBtn}>
                              {apply === i ? "Apply now" : "Apply Now"}
                            </Button>
                          </Link>
                          <hr />
                          <div className={styles.location}>
                            <div className={styles.loc}>
                              <img
                                src={locaImg}
                                alt="country"
                                loading="lazy"
                                height={24}
                                width={24}
                              />
                              <p>
                                {cities?.strapi_json_value.length > 0 &&
                                  cities?.strapi_json_value[0] + ", "}
                                {country}
                              </p>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  )}
                </div>
              </div>
            </Table>
            <div className={`text-center ${styles.mailto}`}>
              <p> You also have the option to submit your resume to</p>
              <div>
                <a href="mailto:careers@invozone.com" className={styles.mail}>
                  careers@invozone.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Vacancies
