import { graphql } from "gatsby"
import React from "react"
import Aspiration from "../components/careers-sections/Aspiration"
import Aspiration2 from "../components/careers-sections/Aspiration2"
import Banner from "../components/careers-sections/Banner"
import Benefits from "../components/careers-sections/Benefits"
import Engineering from "../components/careers-sections/Engineering"
import Vacancies from "../components/careers-sections/Vacancies"
import Vacancies2 from "../components/careers-sections/Vacancies2"
import WhyWork from "../components/careers-sections/WhyWork"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
export const Head = () => {
  return (
    <SEORevamp
      title="Careers at InvoZone | Multinational Software Development Company"
      description="Career opportunities at InvoZone, a multinational IT and software development company based in the USA, with regional offices in Canada, Malaysia, and Pakistan."
      image="https://invozone-backend.s3.amazonaws.com/Career_5c647b87d2.png"
    />
  )
}
const CareersPage = ({ data }) => {
  const vacancies = data?.allStrapiJob?.nodes

  const [isMobile, setIsMobile] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth < 768)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <MainLayout bgChanged={false}>
      <Banner />
      <WhyWork />
      <Benefits />
      {!isMobile ? (
        <Vacancies vacancies={vacancies} />
      ) : (
        <Vacancies2 vacancies={vacancies} />
      )}
      <Engineering />
      {!isMobile ? <Aspiration /> : <Aspiration2 />}
    </MainLayout>
  )
}

export const query = graphql`
  query {
    allStrapiJob(sort: { fields: publishedAt, order: DESC }) {
      nodes {
        slug
        title
        jobType
        overview
        country
        cities {
          strapi_json_value
        }
      }
    }
  }
`

export default CareersPage
