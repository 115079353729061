// extracted by mini-css-extract-plugin
export var btn = "WhyWork-module--btn--41000";
export var cards = "WhyWork-module--cards--826a4";
export var description = "WhyWork-module--description--90c97";
export var hire = "WhyWork-module--hire--ea911";
export var hireCyber = "WhyWork-module--hireCyber--ec470";
export var hireImg = "WhyWork-module--hireImg--6586d";
export var talent = "WhyWork-module--talent--83ec5";
export var teamBtn = "WhyWork-module--teamBtn--28036";
export var text = "WhyWork-module--text--d0c08";
export var tick = "WhyWork-module--tick--d08ea";