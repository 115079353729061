import { Link, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Table from "react-bootstrap/Table"
import * as styles from "./Vacancies.module.scss"
import "./Vacancies.scss"

const Vacancies = ({ vacancies }) => {
  const [jobsData, setJobsData] = useState([])

  const filterResults = label => {
    const filteredResults = vacancies?.filter(
      ({ country }) => country?.toLowerCase() === label.toLowerCase()
    )
    setJobsData(filteredResults)
  }

  useEffect(() => {
    vacancies.length > 0 && filterResults("Pakistan")
  }, [vacancies])

  return (
    <section className={`pb-0 ${styles.vacancies}`}>
      <Container className={styles.con}>
        <div className={styles.scrollBg}>
          <h2>View Current Job Openings</h2>
          <div className={`${styles.my_custom_scrollbar}`}>
            <Table borderless className={`mb-0 pb-0 ${styles.table_card_view}`}>
              <div>
                <div>
                  <div className={styles.scrollOverFlow}></div>
                  <div className={styles.scrollOver}>
                    {jobsData.map(
                      (
                        { title, slug, jobType, overview, country, cities },
                        i
                      ) => (
                        <React.Fragment key={i}>
                          <Row className={styles.jobCards}>
                            <Col lg={4}>
                              <div className="mt-0">
                                <div className={styles.full}>{jobType}</div>
                                <div
                                  className={styles.job}
                                  onClick={() => navigate(`/jobs/${slug}/`)}
                                >
                                  {title}
                                </div>
                                <div className={styles.location}>
                                  <div className={styles.loc}>
                                    <img
                                      src="https://invozone-backend.s3.us-east-1.amazonaws.com/location_e0bdc0bd4e.svg"
                                      alt="country"
                                      loading="lazy"
                                      height={24}
                                      width={24}
                                    />
                                    <p>
                                      {cities?.strapi_json_value.length === 1
                                        ? cities?.strapi_json_value[0] +
                                          ", " +
                                          country
                                        : country}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={5}>
                              <div className={styles.overview}>{overview}</div>
                            </Col>
                            <Col lg={3}>
                              <div>
                                <Link
                                  to={`/jobs/${slug}/`}
                                  className="customstylebutton normal_custom_btn"
                                >
                                  <Button className={styles.applyBtn}>
                                    Apply now
                                  </Button>
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <hr className={styles.hr} />
                        </React.Fragment>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Table>
          </div>
        </div>
        <div className={`text-center ${styles.mailto}`}>
          You can also send your resume to{" "}
          <div>
            <a
              href="mailto:careers@invozone.com"
              className="font-weight-bold text-primary"
            >
              careers@invozone.com
            </a>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Vacancies
