// extracted by mini-css-extract-plugin
export var Onsite = "Vacancies-module--Onsite--f1aff";
export var applyBtn = "Vacancies-module--applyBtn--74b09";
export var con = "Vacancies-module--con--91e73";
export var full = "Vacancies-module--full--06e7f";
export var hr = "Vacancies-module--hr--8410e";
export var job = "Vacancies-module--job--af878";
export var jobCards = "Vacancies-module--jobCards--d90ae";
export var loc = "Vacancies-module--loc--1c522";
export var location = "Vacancies-module--location--51726";
export var mailto = "Vacancies-module--mailto--11683";
export var my_custom_scrollbar = "Vacancies-module--my_custom_scrollbar--38c7d";
export var overview = "Vacancies-module--overview--c733a";
export var scrollBg = "Vacancies-module--scrollBg--41c99";
export var scrollOver = "Vacancies-module--scrollOver--a7ff7";
export var scrollOverFlow = "Vacancies-module--scrollOverFlow--463bd";
export var table_card_view = "Vacancies-module--table_card_view--9ae1f";
export var vacancies = "Vacancies-module--vacancies--01001";